::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(106, 153, 78);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}