.rbc-header{
    border-top-style: solid !important;
    border-width: 2px !important;
    /* opacity: 6.3; */
    border-color: #DDDDDD !important;
    text-align: center !important;
    color: black !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    padding-bottom: 3px !important;
}
.rbc-time-view{
    border-right-style: solid !important;
    border-width: 2px !important;
    /* opacity: 6.3; */
    border-color: #DDDDDD !important;

}

.rbc-row-content {
    border-right-style: solid;
    border-width: 1px;
    border-color: #DDDDDD;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-even {
    border: none;
}


.rbc-day-slot .rbc-event-label {
    display: none;
}

.rbc-time-view .rbc-row {
    min-height: 0px;
}

.rbc-time-gutter > .rbc-timeslot-group > .rbc-time-slot > .rbc-label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: right;
    color: #000000;
    opacity: 0.6;
}

.rbc-event .rbc-event-content{
    white-space: normal !important;
}

.rbc-event-content{
    padding: 0px !important;
    border-radius: 3px;
}

.react-date-picker__wrapper {
    border: 0px !important;
}
.react-date-picker__inputGroup {
    min-width: none !important;
    padding: 0px !important;
}
.calendarDatePicker .react-date-picker__inputGroup {
    display: none ;
}

.react-time-picker__wrapper{
    border: none !important;
}

.react-time-picker__clear-button, .react-date-picker__calendar-button{
    display: none;
}
.timeStyle{
    font-size: 14px !important;
}
.MuiCheckbox-root.Mui-checked > svg.MuiSvgIcon-root{
    border-color: rgba(136, 174, 49, 0.6);
    background: linear-gradient(0deg, rgb(136, 174, 49) 40%, rgba(136, 174, 49, 0.8) 60%, rgba(136, 174, 49, 0.7) 80%, rgba(136, 174, 49, 0.6) 100%);
}
svg[data-testid="CheckBoxIcon"]{
    background-image: linear-gradient(0deg, rgb(136, 174, 49) 40%, rgba(136, 174, 49, 0.8) 60%, rgba(136, 174, 49, 0.7) 80%, rgba(136, 174, 49, 0.6) 100%);

}


.Mui-checked > input {
    background-image:  linear-gradient(0deg, rgb(136, 174, 49) 40%, rgba(136, 174, 49, 0.8) 60%, rgba(136, 174, 49, 0.7) 80%, rgba(136, 174, 49, 0.6) 100%);
}
.MuiRadio-root.MuiRadio-colorPrimary.MuiButtonBase-root:after {
    background-image:  linear-gradient(0deg, rgb(136, 174, 49) 40%, rgba(136, 174, 49, 0.8) 60%, rgba(136, 174, 49, 0.7) 80%, rgba(136, 174, 49, 0.6) 100%);
}
.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
    border-color: rgba(136, 174, 49, 0.6) !important;
}
