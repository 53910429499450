@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(./assets/fonts/OpenSans-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'OpenSans';
    font-weight: 500;
    src: local('OpenSans'), url(./assets/fonts/OpenSans-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'OpenSans';
    font-weight: 600;
    src: local('OpenSans'), url(./assets/fonts/OpenSans-SemiBold.ttf) format('truetype');
}
@font-face {
    font-family: 'OpenSans';
    font-weight: 700;
    src: local('OpenSans'), url(./assets/fonts/OpenSans-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'OpenSans';
    font-weight: 800;
    src: local('OpenSans'), url(./assets/fonts/OpenSans-ExtraBold.ttf) format('truetype');
}
